@import 'custom-variables';

// TOC
// 1 - GENERAL
// 2 - BODY
// 3 - HEADER
//  3.1 - Main navigation
//  3.2 - Social Icons
// 4 - HOME
//  4.1 - Slider
//  4.2 - News
//  4.3 - Events
// --------------------------------------------


// 1 - GENERAL
// --------------------------------------------

// 2 - BODY
// --------------------------------------------

// 3 - HEADER
  #content-header-minisite {
//  3.1 - Main navigation
    #main-navigation {
      .nav > li > a {
        color: $gray-darker;
        font-size: 1.8rem;
      }
    }
  }
//  3.2 - Social Icons
  #portal-social-icons {
    font-size: 2.8rem;
    & > ul {
      display: flex;
      justify-content: flex-end;
    }
    a { 
      color: $gray-light;
      &:hover {
        color: $gray-lighter;
      }
    }
  }
// -------------------------------------------- 

// 4 - HOME
//  4.1 - Slider
  .portalSlider--minisite {
    .minisiteSlider-item {
      .bgimage {
        min-height:none;// 14.0rem;
        height: auto;        
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 9.0rem 0;
      }   
      &__more {
        transition: all $transition-fast ease;
        color: $white;
        border: 1px solid $white;
        background-color: transparent;
        &:hover {
          color: $gray-darker;
          background-color: $white;
        }
      }
      &__image {
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        img {
          width: 100%; height: 100%;
          object-fit: cover;
          object-position: 50% 50%;
        }
      }
    }
    
    // pager
    .bx-wrapper .bx-pager.bx-default-pager a {
      width: 2.0rem; height: 2.0rem;
      border-radius: 1.0rem;
      border: .3rem solid $white;
      &.active {
        background-color: transparent;        
      }
    }    
    // controls
    .bx-controls-horizontal {
      position: absolute;
      top: 50%; left:0; right: 0;
      transform: translateY(-50%);
      display: flex;
      justify-content: space-between;
      & > * {
        flex: 1 0 50%;
      }
    }
  }
  
//  4.2 - News
  #home-news {
    .rounded-icon {
      background-color: #777777;
    }
  }
//  4.3 - Events
  .minisiteEventsItem {
    &__link {
      display: block;
    }
    &__image {
      // margin-left: -1.5rem; margin-right: -1.5rem;
    }
    @media screen and (min-width: $screen-sm-min) {
      &__link {
        display: flex;
      }
    }
  }
// --------------------------------------------
